import { FunctionComponent, ReactElement } from "react"

import { Variation } from "../Logo"
import Navbar from "./Navbar"
import { NavTheme } from "./internal/Theme"
import { PartnerLogo } from "./internal/Logo"

// Some common configurations you might want to use
export const LogoOnly: FunctionComponent = () => <Navbar hasAuth={false} />

export const LogoAndAuth: FunctionComponent<{ partnerLogo?: PartnerLogo }> = ({
  partnerLogo,
}) => <Navbar partnerLogo={partnerLogo} />

const Default: FunctionComponent<{
  theme?: NavTheme
  logoVariation?: Variation
  partnerLogo?: PartnerLogo
  sticky?: boolean
  banner?: ReactElement
}> = ({ theme, logoVariation, partnerLogo, sticky, banner }) => (
  <Navbar
    theme={theme}
    logoVariation={logoVariation}
    partnerLogo={partnerLogo}
    sticky={sticky}
    banner={banner}
    items={[
      {
        _tag: "dropdown",
        text: "Who we are",
        accessibleMenuName: "about",
        items: [
          {
            _tag: "item",
            text: "About us",
            href: "/about-us",
            buttonName: "nav_who_we_are_about_us",
          },
          {
            _tag: "item",
            text: "Habito FAQs & help centre",
            href: "https://help.habito.com/",
            target: "_blank",
            buttonName: "nav_who_we_are_faqs_and_help_centre",
          },
        ],
      },
      {
        _tag: "dropdown",
        text: "How we can help you",
        accessibleMenuName: "services and tools",
        items: [
          {
            _tag: "section",
            name: "Our services",
            items: [
              {
                _tag: "item",
                text: "Get the best mortgage",
                href: "/how-can-we-help",
                buttonName: "nav_how_we_can_help_you_get_a_mortgage",
              },
              {
                _tag: "item",
                text: "Switch your mortgage",
                href: "/how-can-we-help/remortgage",
                buttonName: "nav_how_we_can_help_you_switch_your_mortgage",
              },
              {
                _tag: "item",
                text: "Sort your legal work & mortgage together",
                href: "/home-buying",
                buttonName: "nav_how_we_can_help_you_homebuying",
              },
              {
                _tag: "item",
                text: "Get a mortgage in principle (MIP)",
                href: "/mortgage-in-principle",
                buttonName: "nav_how_we_can_help_you_mip",
              },
            ],
          },
          {
            _tag: "section",
            name: "Protection",
            items: [
              {
                _tag: "item",
                text: "Get protected",
                href: "/protection-get",
                buttonName: "nav_get-protected",
              },
              {
                _tag: "item",
                text: "Why protection is so important",
                href: "/protection-importance",
                buttonName: "nav_why_protection_is_so_important",
              },
              {
                _tag: "item",
                text: "Types of protection",
                href: "/protection-types",
                buttonName: "nav_types_of_protection",
              },
            ],
          },
          {
            _tag: "section",
            name: "Calculators & tools",
            items: [
              {
                _tag: "item",
                text: "Mortgage calculator",
                href: "/mortgage-calculator",
                buttonName: "nav_how_we_can_help_you_mortgage_calculator",
              },
              {
                _tag: "item",
                text: "Remortgage calculator",
                href: "/remortgage-calculator",
                buttonName: "nav_how_we_can_help_you_remortgage_calculator",
              },
              {
                _tag: "item",
                text: "Mortgage comparison table",
                href: "/mortgage-comparison",
                buttonName: "nav_how_we_can_help_you_comparison_table",
              },
            ],
          },
        ],
      },
      {
        _tag: "dropdown",
        text: "Habito exclusives",
        accessibleMenuName: "products",
        items: [
          {
            _tag: "item",
            text: "Complete home-buying service",
            href: "/home-buying",
            buttonName: "nav_exclusives_homebuying_service",
          },
        ],
      },
      {
        _tag: "dropdown",
        text: "Learn",
        accessibleMenuName: "education",
        items: [
          {
            _tag: "item",
            text: "Mortgages 101",
            href: "/hub/topic/mortgages-101",
            target: "_blank",
            buttonName: "nav_learn_mortgages_101",
          },
          {
            _tag: "item",
            text: "Buying a home",
            href: "/hub/topic/buying-a-home",
            target: "_blank",
            buttonName: "nav_learn_buying_a_home",
          },
          {
            _tag: "item",
            text: "Buy-to-let",
            href: "/hub/topic/buy-to-let",
            target: "_blank",
            buttonName: "nav_learn_buy_to_let",
          },
          {
            _tag: "item",
            text: "Moving home",
            href: "/hub/topic/moving-home",
            target: "_blank",
            buttonName: "nav_learn_moving_home",
          },
          {
            _tag: "item",
            text: "Remortgaging",
            href: "/hub/topic/remortgaging",
            target: "_blank",
            buttonName: "nav_learn_remortgaging",
          },
          {
            _tag: "item",
            text: "Impact",
            href: "/hub/topic/impact",
            target: "_blank",
            buttonName: "nav_learn_impact",
          },
          {
            _tag: "item",
            text: "All articles",
            href: "/hub",
            target: "_blank",
            buttonName: "nav_learn_all_articles",
          },
        ],
      },
    ]}
  />
)
export default Default
